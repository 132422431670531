// React
import React, { useEffect, useLayoutEffect, useState } from 'react';

// Prop types
import PropTypes from 'prop-types';

// Redux
import { connect } from 'react-redux';
import { withRoomContext } from '../../../RoomContext';
import * as adminActions from '../../../actions/adminActions';

// Component
import AdminUserEditView from './AdminUserEditView';
import AdminUserLoginDialog from './AdminUserLoginDialog';
import { auth, functions, firestore } from '../../../lib/firebase';
import classnames from 'classnames';
import AdminDateSelectDownload from './AdminDateSelectDownload';
import ConfirmDialog from '../Common/ConfirmDialog';

// Mui styles
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';

// Mui core
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// Mui icon
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

// Firebase
import { useAuthState } from 'react-firebase-hooks/auth';

// Constants
import {
	PLANS, ROOM_TALKING
} from '../../../const';

// dayjs
import dayjs from 'dayjs';

function descendingComparator(a, b, orderBy)
{
	if (b[orderBy] < a[orderBy])
	{
		return -1;
	}
	if (b[orderBy] > a[orderBy])
	{
		return 1;
	}

	return 0;
}

function getComparator(order, orderBy)
{
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator, rowsPerPage)
{
	const stabilizedThis = array.map((el, index) => [ el, index ]);

	stabilizedThis.sort((a, b) =>
	{
		const order = comparator(a[0], b[0]);

		if (order !== 0) return order;

		return a[1] - b[1];
	});

	const duplicateRow = [ ...Array(rowsPerPage) ]
		.map(() => { return { hideAction: true }; });

	return [
		...stabilizedThis.map((el) => el[0]),
		...duplicateRow
	];
}

const headCells = [
	{ id: 'choice', numeric: false, disablePadding: false, isSort: false, label: '選択' },
	{ id: 'number', numeric: false, disablePadding: false, isSort: true, label: '顧客 No.' },
	{ id: 'name', numeric: false, disablePadding: false, isSort: true, label: '契約社名' },
	{ id: 'service', numeric: false, disablePadding: false, isSort: true, label: 'サービス名' },
	{ id: 'plan', numeric: false, disablePadding: false, isSort: true, label: 'プラン' },
	{ id: 'subdomain', numeric: false, disablePadding: false, isSort: true, label: 'サブドメイン' },
	{ id: 'time', numeric: false, disablePadding: false, isSort: true, label: '月間通話時間' },
	{ id: 'status', numeric: false, disablePadding: false, isSort: true, label: '状態' },
	{ id: 'action', numeric: false, disablePadding: false, isSort: true, label: '操作' }
];

const UsersTableHead = (props) =>
{
	const {
		classes, order, orderBy, onRequestSort
	} = props;

	const createSortHandler = (property, isSort) => (event) =>
	{
		if (!isSort) return;
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={'center'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						{headCell.isSort &&
							<TableSortLabel disabled/>
						}
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							hideSortIcon={!headCell.isSort}
							onClick={createSortHandler(headCell.id, headCell.isSort)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

UsersTableHead.propTypes =
{
	classes       : PropTypes.object.isRequired,
	onRequestSort : PropTypes.func.isRequired,
	order         : PropTypes.oneOf([ 'asc', 'desc' ]).isRequired,
	orderBy       : PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) =>
	({
		root : {
			width  : '100%',
			height : 'calc(100% - 7.5rem)'
		},
		paper : {
			width                       : '100%',
			height                      : '100%',
			borderRadius                : '0',
			background                  : 'transparent',
			marginBottom                : theme.spacing(2),
			color                       : 'var(--text-color)',
			'& .MuiTableContainer-root' :
			{
				height : 'calc(100% - 52px)'
			}
		},
		tableContainer : {
			background   : 'white',
			borderRadius : '0.5rem',
			paddingTop   : '1rem',
			fontWeight   : '300',
			color        : 'var(--text-color)'
		},
		table : {
			minWidth                                : 750,
			'& .MuiTableCell-root:not(:last-child)' : {
				borderBottom : 'none',
				borderRight  : '1px solid var(--text-color)',
				fontWeight   : '300',
				fontFamily   : '"M PLUS Rounded 1c", sans-serif',
				color        : 'var(--text-color)'
			},
			'& .MuiTableCell-root:last-child' : {
				borderBottom : 'none',
				fontWeight   : '300',
				fontFamily   : '"M PLUS Rounded 1c", sans-serif',
				color        : 'var(--text-color)'
			}
		},
		visuallyHidden : {
			border   : 0,
			clip     : 'rect(0 0 0 0)',
			height   : 1,
			margin   : -1,
			overflow : 'hidden',
			padding  : 0,
			position : 'absolute',
			top      : 20,
			width    : 1
		},
		tableRow : {
			'&:nth-of-type(odd)' : {
				backgroundColor : theme.palette.action.hover
			}
		},
		noAccount : {
			color     : 'var(--text-color)',
			fontSize  : '1.4rem',
			width     : '100%',
			textAlign : 'center',
			marginTop : '15%'
		}
	}));

const UsersTable = (props) =>
{
	const {
		userSelectedCb,
		rows,
		selected,
		setSelected,
		searchWord,
		page,
		setPage
	} = props;

	const classes = useStyles();
	const [ order, setOrder ] = React.useState('asc');
	const [ orderBy, setOrderBy ] = React.useState('calories');
	const [ rowsPerPage, setRowsPerPage ] = React.useState(5);

	const getStatusText = (status) =>
	{
		if (status === 0)
		{
			return '稼働中';
		}
		else if (status === 1)
		{
			return '停止中';
		}
		else if (status === 2)
		{
			return '退会';
		}
		else if (status === undefined)
		{
			return '不明';
		}
	};

	const handleRequestSort = (event, property) =>
	{
		const isAsc = orderBy === property && order === 'asc';

		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleClick = (event, id) =>
	{
		const selectedIndex = selected.indexOf(id);

		let newSelected = [];

		if (selectedIndex === -1)
		{
			newSelected = newSelected.concat(selected, id);
		}
		else if (selectedIndex === 0)
		{
			newSelected = newSelected.concat(selected.slice(1));
		}
		else if (selectedIndex === selected.length - 1)
		{
			newSelected = newSelected.concat(selected.slice(0, -1));
		}
		else if (selectedIndex > 0)
		{
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) =>
	{
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) =>
	{
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	const getPlanName = (planId) =>
	{
		if (planId === 0 || planId)
		{
			return PLANS.find((plan) => plan.id === planId).name;
		}
		else
		{
			return '不明';
		}
	};

	const showTimeFromSeconds = (s) =>
	{

		const minutes = Math.floor(s/60);

		const seconds = s%60;

		return `${minutes}分${(`0${ seconds}`).slice(-2)}秒`;

	};

	return (
		<Box className={classes.root}>
			<Paper className={classes.paper} elevation={0}>
				<TableContainer className={classes.tableContainer}>
					{ rows?.length > 0 || !searchWord ?
						<Table
							className={classes.table}
							aria-labelledby='tableTitle'
							size={'medium'}
							aria-label='enhanced table'
						>
							<UsersTableHead
								classes={classes}
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
							/>
							<TableBody>
								{stableSort(rows, getComparator(order, orderBy), rowsPerPage)
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row, index) =>
									{
										const isItemSelected = isSelected(row.id);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<React.Fragment key={index}>
												{ row.id &&
													<TableRow
														hover
														onClick={(event) => handleClick(event, row.id)}
														role='checkbox'
														aria-checked={isItemSelected}
														tabIndex={-1}
														selected={isItemSelected}
														className={classes.tableRow}
													>
														<TableCell align='center'>
															<Checkbox
																checked={isItemSelected}
																inputProps={{ 'aria-labelledby': labelId }}
															/>
														</TableCell>
														<TableCell align='center' component='th' id={labelId} scope='row'>
															{row.number}
														</TableCell>
														<TableCell align='center'>{row.name}</TableCell>
														<TableCell align='center'>{row.serviceName}</TableCell>
														<TableCell align='center'>{getPlanName(row.plan)}</TableCell>
														<TableCell align='center'>{row.subdomain}</TableCell>
														<TableCell align='center'>{showTimeFromSeconds(row.durationTotal)}</TableCell>
														<TableCell align='center'>{!row.hideAction && getStatusText(row.status)}</TableCell>
														<TableCell align='center'>
															{ !row.hideAction &&
															<Button
																onClick={() => userSelectedCb(row.id)}
																className={classes.addUserBtn}
																variant='contained'
																color='primary'
															>
																編集
															</Button>
															}
														</TableCell>
													</TableRow>
												}
											</React.Fragment>
										);
									})}
							</TableBody>
						</Table>
						:
						<Typography className={classes.noAccount}>
							表示するアカウントがありません
						</Typography>
					}
				</TableContainer>

				<TablePagination
					rowsPerPageOptions={[ 5, 10, 25 ]}
					component='div'
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</Box>
	);
};

UsersTable.propTypes =
{
	userSelectedCb : PropTypes.func.isRequired,
	rows           : PropTypes.array.isRequired,
	selected       : PropTypes.array.isRequired,
	setSelected    : PropTypes.func.isRequired,
	searchWord     : PropTypes.string,
	page           : PropTypes.number,
	setPage        : PropTypes.func
};

const styles = (theme) =>
	({
		root : {
			width           : '100%',
			height          : `calc(100% - ${theme.spacing(8)}px)`,
			position        : 'relative',
			display         : 'flex',
			flexDirection   : 'column',
			backgroundColor : '#e5e5e2',
			padding         : '2% 4%',
			justifyContent  : 'start',
			margin          : '0 auto',
			overflow        : 'auto'
		},
		rootEdit : {
			height : '100%'
		},
		highlight :
		theme.palette.type === 'light'
			? {
				color           : theme.palette.secondary.main,
				backgroundColor : lighten(theme.palette.secondary.light, 0.85)
			}
			: {
				color           : theme.palette.text.primary,
				backgroundColor : theme.palette.secondary.dark
			},
		title : {
			flex : '1 1 100%'
		},
		appBar : {
			backgroundColor : '#878686'
		},
		header : {
			width          : '100%',
			display        : 'flex',
			alignItems     : 'center',
			justifyContent : 'space-between',
			padding        : '0 1%'
		},
		logo : {
			width  : 'auto',
			height : '2rem'
		},
		headerTitle : {
			fontSize   : '1.8rem',
			color      : 'white',
			lineHeight : '1.9rem',
			marginLeft : '2rem'
		},
		pageTitle : {
			fontSize       : '1.5rem',
			marginBottom   : '1rem',
			color          : 'var(--text-color)',
			display        : 'flex',
			justifyContent : 'space-between',
			height         : '2rem'
		},
		input : {
			width                          : '40%',
			fontSize                       : '1rem',
			marginRight                    : '1rem',
			'& .MuiInput-underline:before' : {
				borderBottom : '1px solid var(--text-color)'
			},
			'& .MuiInput-underline:after' : {
				borderBottom : '1px solid var(--text-color)'
			},
			'& .MuiInput-underline:not(.Mui-disabled):hover::before' : {
				borderBottom : '1px solid var(--text-color)'
			}
		},
		logoutBtn : {
			background  : 'white',
			color       : 'var(--text-color)',
			border      : '1px solid var(--text-color)',
			boxShadow   : 'none',
			marginRight : '2rem'
		},
		addUserBtn : {
			fontSize : '0.9rem'
		},
		forceLeaveBtn : {
			fontSize : '0.9rem'
		},
		forceLeaveBtnWrapper : {
			marginRight : '2rem'
		},
		buttonsWrapper : {
			display : 'flex'
		},
		icon : {
			transform : 'rotate(90deg)'
		},
		loading : {
			width           : '100vw',
			height          : '100vh',
			zIndex          : 2,
			position        : 'fixed',
			top             : 0,
			left            : 0,
			display         : 'flex',
			justifyContent  : 'center',
			alignItems      : 'center',
			backgroundColor : 'rgba(255, 255, 255, 0.5)'
		}
	});

const AdminHome = ({
	classes,
	admin,
	setAdminUserLoginDialogOpen
}) =>
{
	const [ isUserEdit, setIsUserEdit ] = useState(false);
	const [ users, setUsers ] = useState([]);
	const [ user, setUser ] = useState(null);
	const [ isLoading, setLoading ] = useState(false);
	const [ authUser ] = useAuthState(auth);
	const [ signedIn, setSignedIn ] = useState(false);
	const [ selected, setSelected ] = useState([]);
	const [ searchWord, setSearchWord ] = React.useState('');
	const [ filteredUsers, setFilteredUsers ] = React.useState([]);
	const [ page, setPage ] = React.useState(0);

	const [ confirmDialogState, setConfirmDialogState ] = useState(
		{
			show            : false,
			title           : '',
			message         : '',
			acceptMethod    : () => {},
			acceptMethodArg : undefined,
			closeButtonOnly : true
		}
	);

	const [ errors, setErrors ] = useState({
		show   : false,
		errors : []
	});

	const [ roomsNum, setRoomsNum ] = useState(0);
	const [ anchorEl, setAnchorEl ] = React.useState(null);
	const openRoomsSelector = Boolean(anchorEl);
	const handleClickRoomsSelector = (event) =>
	{
		setAnchorEl(event.currentTarget);
	};
	const handleCloseRoomsSelector = () =>
	{
		setAnchorEl(null);
	};

	const closeConfirmDialog = () =>
	{
		setConfirmDialogState(
			{
				show            : false,
				title           : '',
				message         : '',
				acceptMethod    : () => {},
				acceptMethodArg : undefined,
				closeButtonOnly : true
			}
		);
	};

	const addError= (err) =>
	{
		setErrors((state) =>
		{
			const prevErrors = [ ...state.errors ];

			prevErrors.push(err);

			return {
				...state,
				errors : prevErrors
			};
		});
	};

	const showErrors = () =>
	{
		setErrors((state) =>
		{
			return {
				...state,
				show : true
			};
		});
	};

	const createUserAPI = functions.httpsCallable('createUser');
	const deleteUserAPI = functions.httpsCallable('deleteUser');
	const setUserRoleAPI = functions.httpsCallable('setUserRole');

	useEffect(() =>
	{
		if (signedIn)
		{
			setAdminUserLoginDialogOpen(false);
		}

	}, [ setAdminUserLoginDialogOpen, signedIn ]);

	const fetchUsers = () =>
	{

		const startMonth = dayjs().startOf('month');

		firestore.collection('users').orderBy('number')
			.get()
			.then((querySnapshot) =>
			{
				firestore.collection('roles')
					.get()
					.then((rolesData) =>
					{
						firestore.collection('calls')
							.orderBy('timestamp')
							.where('timestamp', '>=', startMonth.valueOf())
							.get()
							.then((callsData) =>
							{

								// get call time
								const durationsMap = new Map();

								callsData.forEach((doc) =>
								{
									const callData = doc.data();

									// first
									if (!durationsMap.get(callData.uid))
									{
										durationsMap.set(callData.uid, callData.duration);
									}
									// after first
									else
									{
										durationsMap.set(
											callData.uid,
											durationsMap.get(callData.uid)+callData.duration // add
										);
									}
								});

								const currentUsers = [];

								const roles = [];

								rolesData.forEach((role) =>
								{
									roles.push(role.data());
								});

								querySnapshot.forEach((doc) =>
								{
									let durationTotal = 0;

									if (durationsMap.get(doc.id))
									{
										durationTotal = durationsMap.get(doc.id);
									}

									let userRole = 'common';

									roles.forEach((r) =>
									{
										if (r.member.find((m) => m === doc.id))
										{
											userRole = r.name;
										}
									});

									currentUsers.push(
										{
											...doc.data(),
											durationTotal : durationTotal,
											role          : userRole
										}
									);
								});

								console.log('currentUsers', currentUsers); // eslint-disable-line no-console

								setUsers(currentUsers);
							});
					});

			});
	};

	useLayoutEffect(() =>
	{
		document.getElementById('SecondDoor').style.padding = isUserEdit ? '2%' : '0';
	}, [ isUserEdit ]);

	useEffect(() =>
	{
		if (authUser)
		{
			auth.signOut();
		}
		setAdminUserLoginDialogOpen(true);
		fetchUsers();
	// eslint-disable-next-line
	}, []);

	const showUserEditView = (id) =>
	{
		const targetUserIndex = users.findIndex((u) => u.id === id);

		if (targetUserIndex !== -1)
		{
			setUser(users[targetUserIndex]);
		}
		else
		{
			setUser(null);
		}

		setIsUserEdit(true);
	};

	const hideUserEditView = () =>
	{
		setIsUserEdit(false);
	};

	const saveUpdatedUserData = async (
		editUser,
		userRoomsData,
		hideUserEditViewMethod,
		updateRole
	) =>
	{
		const usersRef = firestore.collection('users').doc(user.id);

		return firestore.runTransaction((t) =>
		{
			t.update(usersRef, editUser);

			// increase rooms
			if (Number(editUser.roomNum) > Number(user.roomNum))
			{

				// create room
				for (let i = Number(user.roomNum); i < Number(editUser.roomNum); i++)
				{
					const newRoomRef = firestore.collection('rooms').doc();

					t.set(newRoomRef, {
						index     : i,
						uid       : user.id,
						subdomain : editUser.subdomain,
						name      : '',
						password  : '',
						status    : 0,
						user      : ''
					});
				}
			}
			// decrease rooms
			else if (Number(editUser.roomNum) < Number(user.roomNum))
			{
				// delete room
				userRoomsData.forEach((doc) =>
				{

					if (Number(doc.data().index) >= Number(editUser.roomNum))
					{
						t.delete(doc.ref);
					}
				});
			}

			if (!updateRole)
			{
				hideUserEditViewMethod();
			}

			setErrors({
				show   : false,
				errors : []
			});

			return Promise.resolve('transactionComplete');

		}).then(() =>
		{

			setUser((state) =>
			{
				return {
					...state,
					...editUser
				};
			});

			setUsers((state) =>
			{
				const prevState = [ ...state ];
				const updatedUserIndex = state.findIndex((u) => u.id === user.id);

				if (updatedUserIndex !== -1)
				{
					prevState[updatedUserIndex] = {
						...state[updatedUserIndex],
						...editUser
					};
				}

				return [ ...prevState ];

			});

			if (!updateRole)
			{
				setLoading(false);
			}

			return true;

		})
			.catch(() =>
			{
				setConfirmDialogState(
					{
						show            : true,
						title           : 'エラー',
						message         : '保存中にエラーが発生しました。',
						acceptMethod    : () => {},
						acceptMethodArg : undefined,
						closeButtonOnly : true
					}
				);
				setLoading(false);

				return false;
			});

	};

	const validateColumnDuplicate = async (col, colName, editUser) =>
	{

		try
		{
			const doc = await firestore.collection('users').where(col, '==', editUser[col])
				.get();

			if (doc.size > 0)
			{
				setConfirmDialogState(
					{
						show            : true,
						title           : 'エラー',
						message         : `${colName}が重複しています。`,
						closeButtonOnly : true
					}
				);

				return false;
			}
			else
			{
				return true;
			}
		}
		catch
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : 'サーバーエラーが発生しました。',
					closeButtonOnly : true
				}
			);

			return false;
		}

	};

	const saveUser = async (editUser) =>
	{
		setLoading(true);

		// check duplicate subdomain and duplicate email
		if (editUser.id === -1)
		{
			const subdomainPassed = await validateColumnDuplicate('subdomain', 'サブドメイン', editUser);

			if (!subdomainPassed)
			{
				setLoading(false);

				return;
			}

			const emailPassed = await validateColumnDuplicate('email', 'メールアドレス', editUser);

			if (!emailPassed)
			{
				setLoading(false);

				return;
			}
		}

		let error = false;

		setErrors({ show: false, errors: [] });

		if (editUser.id === -1)
		{
			const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

			if (!emailPattern.test(editUser.email))
			{
				error = true;
				addError('メールアドレスが正しくありません。');
			}

			const passwordReg = new RegExp(/^[a-zA-Z0-9]+$/);

			const uppercaseReg = new RegExp(/[A-Z]/);

			const emailStringArray = editUser.email.split('@');

			const numberReg = new RegExp(/^[0-9]+$/);

			if (editUser.adminPassword.length < 8)
			{
				error = true;
				addError('管理者パスワードに設定できる文字列は8文字以上です。');
			}

			if (editUser.adminPassword && passwordReg.test(editUser.adminPassword) === false)
			{
				error = true;
				addError('管理者パスワードに使用可能な文字は半角英数字です。');
			}

			if (editUser.password && passwordReg.test(editUser.password) === false)
			{
				error = true;
				addError('パスワードに使用可能な文字は半角英数字です。');
			}

			if (editUser.adminPassword && uppercaseReg.test(editUser.adminPassword) === false)
			{
				error = true;
				addError('管理者パスワードは大文字アルファベットを含む必要があります。');
			}

			if (editUser.password && editUser.password.length < 8)
			{
				error = true;
				addError('パスワードに設定できる文字列は8文字以上です。');
			}

			if (editUser.tell && numberReg.test(editUser.tell) === false)
			{
				error = true;
				addError('電話番号はハイフンなしの数字のみとしてください。');
			}

			if (editUser.adminPassword && editUser.adminPassword === emailStringArray[0])
			{

				error = true;
				addError('管理者ログインID(メールアドレスの@より前の部分)が同一の文字列はパスワードに設定できません');

			}

		}

		if (error)
		{
			showErrors();
			setLoading(false);

			return;
		}

		if (editUser.id === -1)
		{
			if (!admin.rights?.createUser)
			{
				setConfirmDialogState(
					{
						show            : true,
						title           : 'エラー',
						message         : 'アカウントを作成する権限がありません。',
						acceptMethod    : () => {},
						acceptMethodArg : undefined,
						closeButtonOnly : true
					}
				);

				return;
			}

			createUserAPI({
				...editUser,
				editorId : admin.userId
			})
				.then(() =>
				{
					fetchUsers();
					hideUserEditView();
					setErrors({
						show   : false,
						errors : []
					});
				})
				.catch(() =>
				{
					setConfirmDialogState(
						{
							show            : true,
							title           : 'エラー',
							message         : 'アカウント作成中にエラーが発生しました。',
							acceptMethod    : () => {},
							acceptMethodArg : undefined,
							closeButtonOnly : true
						}
					);
				})
				.finally(() =>
				{
					setLoading(false);
				});
		}
		else
		{
			if (!admin.rights?.editUser)
			{
				setConfirmDialogState(
					{
						show            : true,
						title           : 'エラー',
						message         : 'アカウントを編集する権限がありません。',
						acceptMethod    : () => {},
						acceptMethodArg : undefined,
						closeButtonOnly : true
					}
				);

				return;
			}

			const userRoomsData = [];

			const roleChange = editUser.role !== user.role;

			let updateSuccess = undefined;

			// need updates in rooms collection
			if (Number(editUser.roomNum) < Number(user.roomNum))
			{
				try
				{
					const roomsArray = await firestore.collection('rooms').where('uid', '==', user.id)
						.get();

					roomsArray.forEach((doc) =>
					{
						userRoomsData.push(doc);
					});

					updateSuccess = await saveUpdatedUserData(
						editUser,
						userRoomsData,
						hideUserEditView,
						roleChange
					);
				}
				catch
				{
					setConfirmDialogState(
						{
							show            : true,
							title           : 'エラー',
							message         : '保存中にエラーが発生しました。',
							acceptMethod    : () => {},
							acceptMethodArg : undefined,
							closeButtonOnly : true
						}
					);
					setLoading(false);

					return;

				}

			}
			else
			{
				updateSuccess = await saveUpdatedUserData(
					editUser,
					userRoomsData,
					hideUserEditView,
					roleChange
				);
			}

			if (roleChange && updateSuccess)
			{
				const response = await setUserRoleAPI({
					newRole  : editUser.role,
					id       : editUser.id,
					editorId : admin.userId
				});

				if (response.data?.error === true)
				{
					setConfirmDialogState(
						{
							show            : true,
							title           : 'エラー',
							message         : '管理権限の更新に失敗しました。他の更新は完了しました。',
							acceptMethod    : () => {},
							acceptMethodArg : undefined,
							closeButtonOnly : true
						}
					);
				}

				hideUserEditView();
				setLoading(false);
			}
		}
	};

	const deleteUser = (arg) =>
	{

		if (arg.deleteUserId)
		{

			closeConfirmDialog();
			setLoading(true);
			deleteUserAPI({ id: arg.deleteUserId, editorId: admin.userId })
				.then(() =>
				{
					fetchUsers();
					hideUserEditView();
					setErrors({
						show   : false,
						errors : []
					});
				})
				.catch((e) =>
				{
					console.error(e); // eslint-disable-line no-console
					setConfirmDialogState(
						{
							show            : true,
							title           : 'エラー',
							message         : 'アカウントの削除中にエラーが発生しました。',
							acceptMethod    : () => {},
							acceptMethodArg : undefined,
							closeButtonOnly : true
						}
					);
				})
				.finally(() =>
				{
					setLoading(false);
				});
		}
		else
		{
			closeConfirmDialog();
		}
	};

	const confirmDeleteUser = (data) =>
	{
		if (!admin.rights?.deleteUser)
		{
			setConfirmDialogState(
				{
					show            : true,
					title           : 'エラー',
					message         : 'アカウントを削除する権限がありません。',
					acceptMethod    : () => {},
					acceptMethodArg : undefined,
					closeButtonOnly : true
				}
			);

			return;
		}

		setConfirmDialogState({
			show            : true,
			title           : '確認',
			message         : 'アカウントを削除しますか?',
			acceptMethod    : deleteUser,
			acceptMethodArg : { deleteUserId: data.id },
			closeButtonOnly : false
		});
	};

	const forceLeave = (arg) =>
	{
		handleCloseRoomsSelector();

		// single room
		if (arg.roomNum)
		{
			firestore.collection('rooms').where('uid', '==', arg.id)
				.where('index', '==', Number(arg.roomNum-1))
				.get()
				.then((data) =>
				{

					if (data.docs && data.docs[0] && data.docs[0].id)
					{

						let statusUpdate = {
							status     : 0,
							user       : '',
							forceLeave : true
						};

						// talking
						if (data.docs[0].data().status === ROOM_TALKING)
						{
							statusUpdate = {
								forceLeave : true
							};
						}

						firestore.collection('rooms').doc(data.docs[0].id)
							.update({
								...statusUpdate
							})
							.then(() =>
							{
								setConfirmDialogState({
									show            : true,
									title           : '完了',
									message         : `room${arg.roomNum}を強制退室させました`,
									acceptMethod    : () => {},
									acceptMethodArg : undefined,
									closeButtonOnly : true
								});
							})
							.catch(() =>
							{
								setConfirmDialogState({
									show            : true,
									title           : 'エラー',
									message         : '強制退室に失敗しました',
									acceptMethod    : () => {},
									acceptMethodArg : undefined,
									closeButtonOnly : true
								});
							});
					}
					else
					{
						setConfirmDialogState({
							show            : true,
							title           : 'エラー',
							message         : '強制退室に失敗しました',
							acceptMethod    : () => {},
							acceptMethodArg : undefined,
							closeButtonOnly : true
						});
					}
				});
		}
		// all rooms
		else
		{
			firestore.collection('rooms').where('uid', '==', arg.id)
				.get()
				.then((response) =>
				{

					const batch = firestore.batch();

					response.docs.forEach((doc) =>
					{
						const docRef = firestore.collection('rooms').doc(doc.id);

						let statusUpdate = {
							status     : 0,
							user       : '',
							forceLeave : true
						};

						// talking
						if (doc.data().status === ROOM_TALKING)
						{
							statusUpdate = {
								forceLeave : true
							};
						}
						batch.update(docRef, {
							...statusUpdate
						});

					});

					batch.commit().then(() =>
					{
						setConfirmDialogState({
							show            : true,
							title           : '完了',
							message         : 'すべてのroomを強制退室させました',
							acceptMethod    : () => {},
							acceptMethodArg : undefined,
							closeButtonOnly : true
						});
					})
						.catch(() =>
						{
							setConfirmDialogState({
								show            : true,
								title           : 'エラー',
								message         : '強制退室に失敗しました',
								acceptMethod    : () => {},
								acceptMethodArg : undefined,
								closeButtonOnly : true
							});
						});
				});
		}
	};

	const forceLeaveHandler = (id, roomNum) =>
	{
		if (roomNum)
		{
			setConfirmDialogState({
				show            : true,
				title           : '確認',
				message         : `room${roomNum}を強制退室させますか?`,
				acceptMethod    : forceLeave,
				acceptMethodArg : { id: id, roomNum: roomNum },
				closeButtonOnly : false
			});
		}
		else
		{
			setConfirmDialogState({
				show            : true,
				title           : '確認',
				message         : 'このアカウントのすべてのroomを強制退室させますか?',
				acceptMethod    : forceLeave,
				acceptMethodArg : { id: id, roomNum: roomNum },
				closeButtonOnly : false
			});
		}
	};

	const showRoomsMenu = (roomsQuantity) =>
	{

		const items = [];

		for (let i=1; i<=Number(roomsQuantity); i++)
		{
			items.push(
				<MenuItem
					onClick={() => forceLeaveHandler(user.id, i)} disableRipple
					key={i}
				>
					room{i}
				</MenuItem>);
		}

		return items;

	};

	const logout = () =>
	{
		auth.signOut();
		setAdminUserLoginDialogOpen(true);
	};

	useEffect(() =>
	{
		if (!searchWord)
		{
			setFilteredUsers(users);
		}
		else
		{
			const filteredItems = [];

			users.forEach((row) =>
			{
				if (
					(row.number && row.number.indexOf(searchWord) !== -1)
					|| (row.name && row.name.indexOf(searchWord) !== -1)
					|| (row.serviceName && row.serviceName.indexOf(searchWord) !== -1)
					|| (row.subdomain && row.subdomain.indexOf(searchWord) !== -1)
				)
				{
					filteredItems.push(row);
				}
			});

			setFilteredUsers(filteredItems);
			setPage(0);
		}

	}, [ users, searchWord ]);

	return (
		<>
			{admin.adminUserLoginDialogOpen ?
				<AdminUserLoginDialog setSignedIn={setSignedIn}/>
				:
				<>
					<ConfirmDialog
						show={confirmDialogState.show}
						title={confirmDialogState.title}
						message={confirmDialogState.message}
						accept={confirmDialogState.acceptMethod}
						acceptArg={confirmDialogState.acceptMethodArg}
						cancel={closeConfirmDialog}
						closeButtonOnly={confirmDialogState.closeButtonOnly}
					/>

					{ !isUserEdit &&
						<>
							<AppBar
								position='fixed'
								className={classes.appBar}
								elevation={1}
							>
								<Toolbar>
									<Box className={classes.header} >
										<Box display='flex' alignItems='center'>
											<img
												alt='logo'
												src='images/main_logo_white.png'
												className={classes.logo}
											/>
											<Typography className={classes.headerTitle}>
												システム管理
											</Typography>
										</Box>
										<Box display='flex' alignItems='center'>
											<Button
												className={classes.logoutBtn}
												variant='contained'
												onClick={logout}
											>
												ログアウト
											</Button>
											<Button
												onClick={() => showUserEditView(-1)}
												className={classes.addUserBtn}
												disabled={!admin.rights?.createUser}
												variant='contained'
												color='primary'
											>
												新規作成
											</Button>
										</Box>
									</Box>
								</Toolbar>
							</AppBar>
							<Toolbar />
						</>
					}
					<Box
						className={
							classnames(
								classes.root,
								isUserEdit ? classes.rootEdit : null
							)
						}
						borderRadius={isUserEdit ? '1rem' : '0'}
					>
						<Box className={classes.pageTitle}>
							{isUserEdit ? '契約アカウント管理' : '契約アカウント一覧'}
							{ isUserEdit &&
								<div className={classes.buttonsWrapper}>
									{
										(user && Number(roomsNum) !== 0) &&
										<div className={classes.forceLeaveBtnWrapper}>
											<Button
												className={classes.forceLeaveBtn}
												variant='contained'
												color='secondary'
												onClick={handleClickRoomsSelector}
												endIcon={<PlayArrowIcon className={classes.icon}/>}
											>
												強制退室
											</Button>
											<Menu
												elevation={0}
												getContentAnchorEl={null}
												anchorOrigin={{
													vertical   : 'bottom',
													horizontal : 'center'
												}}
												transformOrigin={{
													vertical   : 'top',
													horizontal : 'center'
												}}
												id='demo-customized-menu'
												MenuListProps={{
													'aria-labelledby' : 'demo-customized-button'
												}}
												keepMounted
												anchorEl={anchorEl}
												open={openRoomsSelector}
												onClose={handleCloseRoomsSelector}
											>
												<MenuItem
													onClick={() => forceLeaveHandler(user.id, null)} disableRipple
												>
													全てのroom
												</MenuItem>
												{ showRoomsMenu(roomsNum) }
											</Menu>
										</div>
									}
									<div>
										<Button
											className={classes.addUserBtn}
											variant='contained'
											color='primary'
											endIcon={<PlayArrowIcon className={classes.icon}/>}
										>
											稼働中
										</Button>
									</div>
								</div>
							}
						</Box>
						{ !isUserEdit &&
							<>
								<Box className={classes.pageTitle} flexDirection='row-reverse'>
									<TextField
										placeholder='検索'
										classes={{
											root : classes.input
										}}
										inputProps={{
											style : {
												fontSize     : '1rem',
												color        : 'var(--text-color)',
												paddingLeft  : '0.3rem',
												paddingRight : '0.3rem'
											}
										}}
										value={searchWord}
										onChange={(e) => setSearchWord(e.target.value)}
									/>
								</Box>

								<AdminDateSelectDownload
									selected={selected}
									users={users}
								/>

							</>
						}
						{ isUserEdit ?
							<AdminUserEditView
								user={user}
								admin={admin}
								saveCb={saveUser}
								deleteCb={confirmDeleteUser}
								closeCb={hideUserEditView}
								errors={errors}
								setErrors={setErrors}
								setRoomsNum={setRoomsNum}
							/>
							:
							<UsersTable
								rows={filteredUsers}
								userSelectedCb={showUserEditView}
								selected={selected}
								setSelected={setSelected}
								searchWord={searchWord}
								page={page}
								setPage={setPage}
							/>
						}
						{ isLoading &&
							<Box className={classes.loading}>
								<CircularProgress />
							</Box>
						}
					</Box>
				</>
			}
		</>
	);
};

AdminHome.propTypes =
{
	classes                     : PropTypes.object.isRequired,
	admin                       : PropTypes.object.isRequired,
	setAdminUserLoginDialogOpen : PropTypes.func.isRequired
};

const makeMapStateToProps = () =>
{
	const mapStateToProps = (state) =>
	{
		return {
			admin : state.admin
		};
	};

	return mapStateToProps;
};

const mapDispatchToProps = (dispatch) =>
{
	return {
		setAdminUserLoginDialogOpen : (adminUserLoginDialogOpen) =>
		{
			dispatch(adminActions.setAdminUserLoginDialogOpen(adminUserLoginDialogOpen));
		}
	};
};

export default withRoomContext(connect(
	makeMapStateToProps,
	mapDispatchToProps,
	null,
	{
		areStatesEqual : (next, prev) =>
		{
			return (
				prev.admin.adminUserLoginDialogOpen === next.admin.adminUserLoginDialogOpen &&
				prev.admin.userId === next.admin.userId &&
				prev.admin.rights === next.admin.rights
			);
		}
	}
)(withStyles(styles)(AdminHome)));
