import { firestore } from './lib/firebase';

/**
 * Create a function which will call the callback function
 * after the given amount of milliseconds has passed since
 * the last time the callback function was called.
 */
export const idle = (callback, delay) =>
{
	let handle;

	return () =>
	{
		if (handle)
		{
			clearTimeout(handle);
		}

		handle = setTimeout(callback, delay);
	};
};

/**
 * Error produced when a socket request has a timeout.
 */
export class SocketTimeoutError extends Error
{
	constructor(message)
	{
		super(message);

		this.name = 'SocketTimeoutError';

		if (Error.hasOwnProperty('captureStackTrace')) // Just in V8.
			Error.captureStackTrace(this, SocketTimeoutError);
		else
			this.stack = (new Error(message)).stack;
	}
}

export const background = () =>
{
	const parts = window.location.hostname.split('.');
	const subdomain = parts.shift();

	let path = `${window.location.protocol}//${window.location.hostname}`;

	path = path.replace(subdomain, 'control');

	return `${path}/uploads/bg_${subdomain}`;
};

export const backgroundTop = () =>
{
	const parts = window.location.hostname.split('.');
	const subdomain = parts.shift();

	let path = `${window.location.protocol}//${window.location.hostname}`;

	path = path.replace(subdomain, 'control');

	return `${path}/uploads/top_${subdomain}`;
};

export const backgroundTopSP = () =>
{
	const parts = window.location.hostname.split('.');
	const subdomain = parts.shift();

	let path = `${window.location.protocol}//${window.location.hostname}`;

	path = path.replace(subdomain, 'control');

	return `${path}/uploads/topsp_${subdomain}`;
};

export const logo = () =>
{
	const parts = window.location.hostname.split('.');
	const subdomain = parts.shift();

	let path = `${window.location.protocol}//${window.location.hostname}`;

	path = path.replace(subdomain, 'control');

	return `${path}/uploads/logo_${subdomain}`;
};

export const hiraToKata = (str) =>
{
	return str.replace(/[\u3041-\u3096]/g, (ch) =>
		String.fromCharCode(ch.charCodeAt(0) + 0x60)
	);
};

export const isZenkakuKana = (s) =>
{
	return !!s.match(/^[ァ-ヶー　]*$/); // eslint-disable-line no-irregular-whitespace, no-implicit-coercion
};

export const cleanDb = async () =>
{
	// Data recover script

	const querySnapshot = await firestore.collection('rooms').get();

	for (let i = 0; i < querySnapshot.docs.length; i++)
	{
		const doc = querySnapshot.docs[i];

		if (doc.exists)
		{
			const data = doc.data();

			if (data.id !== doc.id)
			{
				console.log('data', data); // eslint-disable-line no-console

				try
				{
					await firestore.collection('rooms').doc(data.id)
						.set(doc.data());
					await firestore.collection('rooms').doc(doc.id)
						.delete();
				}
				catch (e)
				{
					console.error(e); // eslint-disable-line no-console
				}

			}
		}
	}
};